<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "DBM",
};
</script>

<style lang="scss">
#app {
  font-family: "SF Pro Text" Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
b {
  margin-bottom: 0;
  letter-spacing: 0.3px;
}

.fullscreen {
  min-height: 100vh;
  padding-top: 20vh;
  padding-bottom: 25vh;
}
.halfscreen {
  min-height: 75vh;
  padding-top: 15vh;
  padding-bottom: 15vh;
}
.quarterscreen {
  min-height: 65vh;
  padding-top: 15vh;
  padding-bottom: 15vh;
}

.form-control {
  border-radius: 0;
  outline: none;
  border: 1.5px dashed;
  margin-bottom: 10px;
  padding: 10px;
}
.form-control:hover {
  outline: none !important;
  outline-width: 0 !important;
  color: rgba($color: #000000, $alpha: 1);
  background-color: unset;
  border: 2px solid;
  // font-weight: 700;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}
.form-control:active,
.form-control:focus-visible,
.form-control:focus {
  color: rgba($color: #000000, $alpha: 1) !important;
  background-color: unset !important;
  border: 1.8px solid;
  font-weight: 700;

  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
}

.btn {
  margin-top: 60px;
  border-radius: 0;
  outline: none !important;
  outline-width: 0 !important;
  border: 1.5px dashed;
  background-color: unset;
  color: rgba($color: #000000, $alpha: 1);
  font-weight: 700;
}
.btn:hover {
  outline: none !important;
  outline-width: 0 !important;
  color: rgba($color: #000000, $alpha: 1);
  background-color: unset;
  border: 2px solid;
  font-weight: 700;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}
.btn:active,
.btn:focus-visible,
.btn:focus {
  color: rgba($color: #000000, $alpha: 1) !important;
  background-color: unset !important;
  border: 1.8px solid;
  font-weight: 700;

  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
}
</style>
