<template>
  <div>
    <div class="nav d-none d-sm-block">
      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col cols="auto">
            <div class="nav-links">
              <router-link :to="{ hash: '#home' }" class="nav-link"
                >Главная</router-link
              >
              <router-link :to="{ hash: '#instruments' }" class="nav-link"
                >Инструменты</router-link
              >
              <router-link :to="{ hash: '#services' }" class="nav-link"
                >Услуги</router-link
              >
              <router-link :to="{ hash: '#more' }" class="nav-link"
                >Подробнее</router-link
              >
              <router-link :to="{ hash: '#contacts' }" class="nav-link"
                >Контакты</router-link
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div id="home" class="header">
      <b-container>
        <b-row class="fullscreen" align-v="center" align-h="start">
          <b-col cols="12" md="8">
            <div class="header-title_block">
              <h1 class="header-title">DBM</h1>
              <h2 class="header-subtitle">Мы помогаем бизнесу расти</h2>
            </div>

            <p class="header-description">
              Обеспечиваем минимизацию рутины, оптимизацию трудовых и
              производственных ресурсов с целью наращивания продуктивности и
              эффективности всех бизнес-процессов
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div id="instruments" class="instruments">
      <b-container>
        <b-row align-h="center">
          <b-col cols="12">
            <h2 class="block_title">инструменты</h2>
          </b-col>
        </b-row>

        <b-row
          v-for="(item, index) in instruments"
          :key="index"
          class="halfscreen"
          align-v="center"
          :class="
            index % 2 != 0
              ? 'justify-content-start instruments-left'
              : 'justify-content-end instruments-right'
          "
        >
          <b-col cols="12" md="8">
            <div
              class="instruments-logo_block-right"
              :class="
                index % 2 != 0 ? 'justify-content-start' : 'justify-content-end'
              "
            >
              <img
                class="instruments-logo"
                :src="require('@/assets/img/' + item.logo)"
                alt="Битрикс24"
              />
              <p v-if="item.title" class="instruments-title">
                {{ item.title }}
              </p>
            </div>

            <p class="instruments-text">{{ item.text }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div id="services" class="services">
      <b-container>
        <b-row align-h="center">
          <b-col cols="12">
            <h2 class="block_title">услуги</h2>
          </b-col>
        </b-row>

        <b-row
          v-for="(item, index) in services"
          :key="index"
          class="quarterscreen"
          align-v="center"
          :class="
            index % 2 != 0
              ? 'justify-content-start instruments-left'
              : 'justify-content-end instruments-right'
          "
        >
          <b-col cols="12" md="8">
            <div
              class="instruments-logo_block-right"
              :class="
                index % 2 != 0 ? 'justify-content-start' : 'justify-content-end'
              "
            >
              <p v-if="item.title" class="services-title">{{ item.title }}</p>
            </div>

            <p class="services-text">{{ item.description }}</p>
          </b-col>
        </b-row>
      </b-container>

      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col cols="12" md="7">
            <p class="callback_form">
              Тут форма обратной связи, чтобы получить больше информации
            </p>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <img
            class="callback_form-arrow"
            :src="require('@/assets/img/arrow.svg')"
            alt=""
          />
        </b-row>

        <b-row id="more" class="fullscreen" align-h="center" align-v="center">
          <b-col cols="12" sm="9" md="7" lg="5">
            <b-form @submit="sendForm">
              <b-form-group>
                <label for="name">*Имя:</label>
                <b-form-input
                  id="name"
                  v-model="form.name"
                  type="text"
                  required
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label for="email">*Email:</label>

                <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  required
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label for="phone">Телефон:</label>
                <b-form-input
                  id="phone"
                  v-model="form.phone"
                  type="tel"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="privacy"
                  v-model="form.privacy"
                  name="privacy"
                  :value="true"
                  :unchecked-value="false"
                  required
                >
                  Нажимая кнопку отправить, Вы даете согласие на обработку
                  персональных данных в соответствии с Положением об обработке
                  персональных данных
                </b-form-checkbox>
              </b-form-group>

              <b-button
                v-if="btnMsg"
                :disabled="disabled"
                size="lg"
                block
                type="submit"
                >{{ btnMsg }}</b-button
              >
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div id="contacts" class="footer">
      <b-container>
        <b-row align-h="between" align-v="start">
          <b-col class="footer-content_block" cols="12" md="5">
            <p class="footer-text">
              Общество с ограниченной ответственностью "ДИ-БИ-МАТЕРИАЛС"
            </p>

            <p class="footer-heading">Телефон:</p>
            <p class="footer-text">+7 (967) 612 55-70<br />
              +7 (391) 272 55-70
            </p>

            <p class="footer-heading">Email:</p>
            <p class="footer-text">info@dbmaterials.ru</p>

            <p class="footer-heading">ИНН:</p>
            <p class="footer-text">2461035432</p>

            <p class="footer-heading">КПП:</p>
            <p class="footer-text">246101001</p>

            <p class="footer-heading">ОГРН:</p>
            <p class="footer-text">1172468002640</p>

            <p class="footer-heading">Юридический адрес:</p>
            <p class="footer-text">
              660059, Красноярский край, город Красноярск, пр-кт Им.Газеты
              "Красноярский Рабочий", д. 65а, кв. 36
            </p>
          </b-col>

          <b-col class="footer-content_block" cols="12" md="7" lg="6">
            <p class="footer-heading">
              Основной вид экономической деятельности:
            </p>
            <p class="footer-text">
              63.11 Деятельность по обработке данных, предоставление услуг по
              размещению информации и связанная с этим деятельность.
            </p>

            <p class="footer-heading">Дополнительные виды деятельности:</p>
            <p class="footer-text">
              1. Торговля розничная, осуществляемая непосредственно при помощи
              информационно-коммуникационной сети Интернет.<br />
              2. Разработка компьютерного программного обеспечения.<br />
              3. Деятельность консультативная и работы в области компьютерных
              технологий.<br />
              4. Деятельность, связанная с использованием вычислительной техники
              и информационных технологий, прочая.<br />
              5. Деятельность web-порталов.<br />
              6. Деятельность рекламных агентств.<br />
              7. Исследование конъюнктуры рынка и изучение общественного
              мнения.<br />
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomeView",
  components: {},
  data: () => ({
    hook: "https://dbmaterials.bitrix24.ru/rest/342/njbdrk078u64nz9n/",
    disabled: false,
    btnMsg: "Отправить",

    form: {
      name: "",
      email: "",
      phone: "",
      privacy: false,
    },
    instruments: [
      {
        logo: "bitrix24 logo.svg",
        text: `
        Самая популярная система для автоматизации. Среди пользователей  не только отечественные Роснефть, «Связной», Внешэкономбанк и прочие, но и такие иностранные гиганты как Volkswagen, Samsung. Сегодня системой автоматизации бизнеса пользуется более 7 млн. клиентов, представляющих малый, средний, крупный бизнес, что говорит об универсальности, надежности программы. Это крупный корпоративный портал, покрывающий практически все задачи коммерческих предприятий.
        `,
      },
      {
        logo: "1c logo.svg",
        text: `
        Продукт для автоматизации предприятий. Перед пользователями открыт широкий ассортимент типовых решений и отраслевых конфигураций. С их помощью возможно автоматизировать бизнес-процессы в любой компании, независимо от масштабов и направлений. Программы 1С:Предприятие созданы специально для решения всех известных задач по управлению компанией и учету. Конфигурация может содержать в себе не только стандартный набор общих функций, но и возможности под конкретную отрасль с учетом индивидуальных задач отдельной компании. Любую программу «1С:Предприятие» можно адаптировать для конкретной отрасли.
        `,
      },
      {
        logo: "vuejs logo.svg",
        title: "Vue.js",
        text: `
        Один из самых популярных JavaScript-фреймворков для создания пользовательских интерфейсов (UI) и одностраничных приложений. Создателем Vue.js является бывший инженер Google Эван Ю. При помощи Vue.js создаются web-приложения любой сложности. Так же можно создавать PWA (progressive web application) которые можно использовать как нативные приложения с возможностью установки на устройство.
        `,
      },
      {
        logo: "laravel logo.svg",
        title: "Laravel",
        text: `
        PHP-фреймворк с открытым исходным кодом, специально разработанный для создания сложных сайтов и веб-приложений. Позволяет упростить аутентификацию, маршрутизацию, сессии, кэширование, архитектуру приложения, работу с базой данных. Laravel помогает определить структуру веб-приложения и служит для нее каркасом. Фреймворк написан на PHP и расширяет его возможности. Используется для создания веб-приложений и сайтов на основе MVC (model-view-controller).
        `,
      },
    ],
    services: [
      {
        title: `
        Разработка web-приложений
        `,
        description: `
        Разработка приложения с нуля "под ключ" или доработка существующего
        `,
      },
      {
        title: `
        Создание сайтов
        `,
        description: `
        Landing page, интернет-магазин, своя универсальная CRM
        `,
      },
      {
        title: `
        UI/UX Design
        `,
        description: `
        Разработаем дизайн сайта/приложения, который можно передать в работу верстальщику
        `,
      },
      {
        title: `
        Автоматизация бизнеса на базе CRM
        `,
        description: `
        Повышение эффективности бизнеса, увеличение продаж, повышение конверсии
        `,
      },
      {
        title: `
        Создание приложений для Битрикс24
        `,
        description: `
        Встроенные в интерфейс CRM приложения для повышения удобства работы и раширения функционала
        `,
      },
      {
        title: `
        Создание activity для Битрикс24
        `,
        description: `
        Добавление собственных действий в дизайнере бизнес-процессов
        `,
      },
      {
        title: `
        Кастомный Dashboard под разные системы
        `,
        description: `
        Цифровизация KPI, удобная аналитика бизнеса и оценка эффективности
        `,
      },
    ],
  }),
  methods: {
    async sendForm(event) {
      event.preventDefault();
      console.log(this.form);

      this.disabled = true;
      this.btnMsg = "Отправляется ⌛️";

      try {
        let result = await axios.post(this.hook + "crm.contact.add.json", {
          fields: {
            NAME: this.form.name,
            PHONE: [
              {
                VALUE: this.form.phone,
                VALUE_TYPE: "WORK",
              },
            ],
            EMAIL: [
              {
                VALUE: this.form.email,
                VALUE_TYPE: "WORK",
              },
            ],
          },
        });
        let contactID = result.data.result;

        await axios.post(this.hook + "crm.deal.add.json", {
          fields: {
            CATEGORY_ID: 96,
            TITLE: `Заявка с сайта DBM ${this.form.name}`,
            CONTACT_ID: contactID,
          },
        });

        this.btnMsg = "Форма успешно отправлена 👍";
      } catch (error) {
        console.error(error);
        this.disabled = false;

        this.btnMsg = "Ошибка отправки 😕";
      }
    },
  },
};
</script>

<style lang="scss">
// header
.nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;

  border-bottom: 1.4px solid;
  border-bottom-color: rgba($color: #000000, $alpha: 0.1);
  background-color: rgba($color: #fff, $alpha: 1);
}
.nav-links {
  display: flex;
}
.nav-link {
  color: rgba($color: #000000, $alpha: 0.7);
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  // width: 120px;

  text-align: center;

  padding: 4px 10px;
  margin: 0 15px;

  // text-transform: uppercase;
}
.nav-link:hover {
  color: rgba($color: #000000, $alpha: 1);
}

.header-title_block {
  padding-bottom: 40px;
}
.header-title {
  font-size: 90px;
  line-height: 90px;
  font-weight: 800;
  color: rgba($color: #000, $alpha: 1);
  margin-bottom: 0;
}
.header-subtitle {
  font-size: 40px;
  font-weight: 400;
  color: rgba($color: #000, $alpha: 0.7);
  letter-spacing: 1px;
}
.header-description {
  font-size: 22px;
  line-height: 27px;

  font-weight: 300;
  color: rgba($color: #000, $alpha: 0.7);
}

// block
.block_title-left {
  text-align: start;
}
.block_title-right {
  text-align: end;
}

.block_title {
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  color: inherit;
  padding-top: 15vh;
}

// instruments
.instruments {
  color: rgba($color: #ffffff, $alpha: 0.2);
  background-color: rgba($color: #000000, $alpha: 1);

  padding-bottom: 20vh;
  min-height: 100vh;
}
.instruments-left {
  text-align: start;
}
.instruments-right {
  text-align: end;
}

.instruments-logo {
  margin-left: 10px;
  margin-right: 10px;
  height: 48px;
  width: auto;
}
.instruments-title {
  color: rgba($color: #ffffff, $alpha: 1);
  font-size: 38px;
  font-weight: 700;
}
.instruments-text {
  font-size: 20px;
  line-height: 28px;
  color: rgba($color: #fff, $alpha: 0.9);
  font-weight: 300;
  letter-spacing: 1.4px;
}
.instruments-logo_block-right {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

// services
.services {
  color: rgba($color: #000000, $alpha: 0.2);
}

.services label {
  color: rgba($color: #000000, $alpha: 0.5);
  text-transform: uppercase;
  font-size: 14px;
}
.services-title {
  color: rgba($color: #000, $alpha: 1);
  font-size: 48px;
  line-height: 50px;
  font-weight: 700;
}
.services-text {
  font-size: 20px;
  line-height: 27px;
  color: rgba($color: #000, $alpha: 0.9);
  font-weight: 300;
  letter-spacing: 1.4px;
}

// callback
.callback_form {
  font-size: 50px;
  color: rgba($color: #000000, $alpha: 1);
  font-weight: 800;
  text-align: center;
  line-height: 60px;
  padding-top: 22vh;
}
.callback_form-arrow {
  height: 14vh;
  width: auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

// footer
.footer {
  min-height: 100vh;
  padding-top: 15vh;
  padding-bottom: 10vh;
  background-color: #000000;
  color: rgba($color: #fff, $alpha: 0.6);
}
.footer-content_block {
  padding-top: 5vh;
  padding-bottom: 5vh;
}
.footer-heading {
  color: rgba($color: #fff, $alpha: 0.6);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.footer-text {
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 15px;
  text-transform: uppercase;
}

// iPhone 5s
@media only screen and (max-width: 375px) {
  .header-title {
    font-size: 80px;
  }
  .header-subtitle {
    font-size: 32px;
    line-height: 30px;
  }
  .header-description {
    font-size: 18px;
    line-height: 20px;
  }

  .instruments-title {
    font-size: 32px;
  }
  .instruments-text {
    font-size: 16px;
    line-height: 20px;
  }
  .instruments-logo {
    max-height: 7vh;
  }

  .services-title {
    font-size: 30px;
    line-height: 30px;
  }
  .services-text {
    font-size: 16px;
    line-height: 20px;
  }

  .block_title {
    font-size: 30px;
  }

  .callback_form {
    font-size: 30px;
    line-height: 35px;
  }

  .footer-heading {
    font-size: 12px;
  }
  .footer-text {
    font-size: 14px;
  }
}
</style>
